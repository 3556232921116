<template>
	<div class="privacy">
		<!-- 头部 -->
		<logged-heard></logged-heard>
		<!-- 搜索 -->

		<!-- 内容区域 -->
		<!-- 手机号获取验证码登录注册 -->
		<div class="el-login">
			<div class="search">
				<search :search="false" :hot="false" :jobCity="false" @changeCity="changeCity"
					@threeCategory="threeCategory"></search>
			</div>
		</div>
		<div style="justify-content: start;" class="el-job-list space-between">
			<div class="el-list-item pointer" v-for="item in list" :key="item.id" @click="Company_details1(item.id)">
				<div class="el-list-top space-between">
					<img :src="item.logo_url" class="el-img-top" alt="" />
					<div class="el-list-top-right row">
						<div class="el-right-text">
							<div class="text-font text-ellipsis ellip">{{ item.ent_name }}</div>
							<div>
								{{ item.other_info.nature.text }}·{{ item.other_info.scale.text }}
							</div>
						</div>
						<img src="../../assets/forget/10.png" class="el-mq-img" alt="" v-if="query.is_famous == 1" />
					</div>
				</div>
				<div class="el-list-bottom row">
					<div>热招</div>
					<div v-if="item.positions.length == 0">无</div>
					<div v-if="item.positions.length > 0">{{ item.positions[0].position_name }}
					</div>
					<div v-if="item.positions.length > 0">
						{{ item.positions[0].salary_min }}-{{ item.positions[0].salary_max }}
					</div>
				</div>
			</div>
			<div class="el-list-item-one" v-if="list.length%4==3 || list.length%4==2"></div>
			<div class="el-list-item-one" v-if="list.length%4==2"></div>
			<!-- 分页 -->
		</div>
		<div class="el-pading">
			<div class="fen">
				<el-pagination background layout="prev, pager, next" :total="total" :hide-on-single-page="valuePageShow"
					@current-change="currentChange" :page-size="12">
				</el-pagination>
			</div>
		</div>
		<!-- 底部 -->
		<feet-model></feet-model>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import feetModel from "../../components/feet.vue";
	import tabbarModel from "../../components/tabbar.vue";
	import search from "../../components/search.vue";
	import api from "../../api/enterprises";
	import apiDuck from "../../api/user"; //hyz
	export default {
		components: {
			loggedHeard,
			feetModel,
			tabbarModel,
			search
		},

		data() {
			return {
				authcode: "",
				phone: "",
				list: [],
				total: 1,
				query: {
					pageSize: 12,
					page: 1,
					province_id: "", //省份
					nature: "", //企业性质
					scale: "", //企业规模
					industry: "", //所属行业
					is_famous: 0,
				},
				valuePageShow: true,
			};
		},

		created() {
			// apiDuck.userDetail().then((res) => {
			// 	if (res.code == 200) {
			// 		this.$store.commit("SET_USER", res.data);
			// 		if (res.data.user_type == 2) {
			// 			this.$emit("toggleClick");
			// 			return;
			// 		}
			// 	}
			// });
			
			let query = this.$route.query;
			if (query.category == 1) {
				this.query.is_famous = 1;
			}
			this.getEnterpriseList();
		},

		mounted() {},

		methods: {
			// 根据企业性质筛选
			threeCategory(id, text) {
				if (text == "scale") {
					this.query.scale = id;
				} else if (text == "nature") {
					this.query.nature = id;
				} else {
					this.query.industry = id;
				}
				this.query.page = 1;
				this.getEnterpriseList();
			},
			// 城市筛选
			changeCity(id) {
				this.query.province_id = id;
				this.query.page = 1;
				this.getEnterpriseList();
			},
			currentChange(val) {
				this.query.page = val;
				this.getEnterpriseList();
			},
			// 获取企业列表
			getEnterpriseList() {
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				api.getEnterpriseList(this.query).then((res) => {
					if (res.code == 200) {
						this.list = res.data.data;
						this.total = res.data.total;
						if (res.data.last_page > 1) {
							this.valuePageShow = false;
						}
					}
					loading.close()
				});
			},
			//   跳转企业详情页面
			Company_details1(id) {
				this.$router.push({
					path: "/Company-details",
					query: {
						id
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.privacy {
		width: 100%;
		background: #f6f6f8;

		.el-login {
			width: 100%;
			height: 203px;
			padding-left: 192px;
			margin-top: 1px;
			background: #ffffff;
			box-shadow: 1px 2px 14px 3px rgba(93, 93, 93, 0.08);

			.search {
				width: 100%;
				padding-top: 1.25rem;
			}
		}

		.el-job-list {
			margin: 0 auto;
			margin-top: 30px;
			width: 90%;
			min-width: 1200px;
			padding: 0 5%;
			flex-wrap: wrap;

			.el-list-item-one {
				min-width: 369px;
				height: 1px;
			}

			.el-list-item {
				min-width: 369px;
				background: #ffffff;
				box-shadow: 0px 1px 9px 0px rgba(163, 162, 163, 0.08);
				padding: 21px 17px 0 20px;
				margin-bottom: 20px;
				margin-left: 10px;

				.el-list-top {
					width: 100%;
					padding-bottom: 20px;
					padding-right: 3px;
					border-bottom: 1px solid #f2f5fa;

					.el-img-top {
						width: 57px;
						height: 57px;
						border-radius: 11px;
					}

					.el-list-top-right {
						font-weight: 400;
						color: #898e9d;
						font-size: 14px;

						.el-right-text {

							// text-align: right;
							.text-font {
								color: #414a60;
								font-size: 16px;
								margin-bottom: 10px;
								max-width: 140px;
							}
						}

						.el-mq-img {
							margin-left: 10px;
							width: 17px;
							height: 20px;
						}
					}
				}

				.el-list-bottom {
					width: 100%;
					height: 42px;
					line-height: 42px;
					font-size: 13px;
					color: #8d92a1;

					div:nth-child(2) {
						color: #126bf9;
						margin: 0 8px 0 5px;
					}

					div:last-child {
						color: #fd7240;
					}
				}
			}
		}
	}

	.el-pading {
		width: 100%;
		margin-bottom: 80px;

		.fen {
			margin: 0 auto;
			position: absolute;
			transform: translate(-50%);
			left: 50%;
		}

		::v-deep.btn-prev {
			background: #fff;
		}

		::v-deep .el-pagination.is-background .btn-next,
		.el-pagination.is-background .btn-prev,
		.el-pagination.is-background .el-pager li {
			background: #fff;
		}

		::v-deep .el-pagination.is-background .el-pager li {
			background: #fff;
			color: #414a60;
		}

		::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
			background: #126bf9;
			color: #fff;
		}
	}
</style>
